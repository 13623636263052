import { ArrowRight } from '@phosphor-icons/react';
import { SimPlanModel } from 'api-hooks/sim/model';
import classNames from 'classnames';
import { CustomValue } from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import colors from 'common/styles/colors';
import Button from 'components/elements/button';
import Text from 'components/elements/text/base';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import { DateWithTimezone } from 'modules/common/activation';
import EsimPlanComponent from 'modules/esim/components/item';
import { RatingShort } from 'modules/feedback/components/rating';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import { EsimActionStyles } from './styles.css';

interface EsimPlanItemComponentProps {
  data: SimPlanModel;
}

export function ActiveEsimPlanComponent(props: EsimPlanItemComponentProps) {
  const { data } = props;
  return (
    <EsimPlanComponent
      name={data.name}
      grade={data.grade}
      customization={{
        body: {
          layout: {
            className: CustomValue.overwrite(
              structuralStyles.flexbox({
                direction: 'column',
                align: 'stretch',
                fill: true,
              }),
            ),
          },
        },
      }}
      Flag={
        <EsimPlanComponent.Flag
          type={data.type}
          areaCode={data.areaCode}
          scale={data.scale}
          estimatedExpiration={data.estimatedExpiration}
          isSubscribed={!!data.subscription}
          isActivated={data.activatedAt != null}
          quota={{
            remaining: data.quotaRemaining,
            limit: data.quotaLimit,
          }}
        />
      }
      Right={
        <EsimPlanComponent.Usage
          activationAt={data.activationAt}
          activatedAt={data.activatedAt}
          endAt={data.endAt}
          estimatedExpiration={data.estimatedExpiration}
          limit={data.quotaLimit}
          remaining={data.quotaRemaining}
          startAt={data.startAt}
          status={data.status}
          type={data.type}
          validityDays={data.validityDays}
        />
      }
    />
  );
}

export function UpcomingEsimPlanComponent(props: EsimPlanItemComponentProps) {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <EsimPlanComponent
      name={data.name}
      grade={data.grade}
      Flag={
        <EsimPlanComponent.Flag
          areaCode={data.areaCode}
          scale={data.scale}
          type={data.type}
        />
      }
      Right={
        <>
          <EsimPlanComponent.Label
            dataType={data.type}
            quotaInGb={data.quotaLimit}
            validityDays={data.validityDays}
            recharge={null}
          />
          <DateWithTimezone date={data.activationAt || data.startAt!}>
            {(datestr) =>
              datestr
                ? t('sim:activation_on', {
                    extra: datestr,
                  })
                : '-'
            }
          </DateWithTimezone>
        </>
      }
    />
  );
}

export function PastEsimPlanComponent(props: EsimPlanItemComponentProps) {
  const { data } = props;
  const { t } = useTranslation();
  const { push } = useKurosimNavigation();
  return (
    <EsimPlanComponent
      name={data.name}
      grade={data.grade}
      Flag={
        <EsimPlanComponent.Flag
          areaCode={data.areaCode}
          scale={data.scale}
          type={data.type}
        />
      }
      Right={
        <>
          <EsimPlanComponent.Label
            dataType={data.type}
            quotaInGb={data.quotaLimit}
            validityDays={data.validityDays}
            recharge={null}
          />
          <div
            className={classNames(
              structuralStyles.flexbox({
                direction: 'row',
                justify: 'between',
                align: 'end',
                fill: true,
              }),
              structuralStyles.fill({ width: true }),
            )}
          >
            <div>
              {data?.activatedAt ? (
                <DateWithTimezone date={data.activatedAt} pb={4}>
                  {(datestr) =>
                    t('sim:activated_on', {
                      extra: datestr,
                    })
                  }
                </DateWithTimezone>
              ) : (
                <Text textVariant="body2Regular" pb={4} ta="left">
                  {t('sim:not_activated')}
                </Text>
              )}
              <EsimPlanComponent.Price
                sellPrice={data.price}
                discountedPrice={data.discountedPrice}
                currency={data.currencyCode}
                total={undefined}
              />
            </div>

            {data.feedback ? (
              data.feedback.rate ? (
                <RatingShort rating={data.feedback.rate} />
              ) : (
                <Button
                  className={EsimActionStyles.rateButton}
                  variant={{ size: 'small', variant: 'secondary' }}
                  onClick={() => {
                    push(NavigationRoutes.Feedback, {
                      paths: {
                        id: data.feedback!.id,
                      },
                    });
                  }}
                >
                  <div className={structuralStyles.flexbox({ gap: 4 })}>
                    <Text textColor="buttonPrimary">
                      {t('sim:rating.rate')}
                    </Text>
                    <ArrowRight color={colors.buttonPrimary} />
                  </div>
                </Button>
              )
            ) : undefined}
          </div>
        </>
      }
    />
  );
}
